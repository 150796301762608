#login-subscribe-container, .login-subscribe-container {
  padding: 56px 0 56px;

  @include breakpoint(small down) {
    @include pt-s;
  }
}


.login-title {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  @include mt-xs;
}

.login-link {
  @include first-font-semibold;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.1em;
}

.remember-me {
  margin-bottom: 16px;
}

.login-subscribe-text {
  border-bottom: 1px solid $grey-2;
  @include pb-xxs;

  a {
    color: $grey-1;
    &.active {
      @include first-font-semibold;
      color: $black;
    }
  }
}

.login_subscribe-box {
  border-top: 1px solid $grey-2;
  @include pt-xs;
}

.login-img {
  height: calc(100vh - 169px);
  object-fit: cover;
  width: 100%;
}

.already-subscribe {
  border-top: 1px solid $grey-2;
  @include pt-xs;
}

//subscribe

.border-top-subscribe{
  border-top: 1px solid $dark-grey;
  padding-top: 20px;
}

//facebook icon

.fb-icon{
  margin-right: 20px;
}

//page login cms 

.box-login-hero {
  background-color: $white;
  padding: 20px 40px;
  min-height: 100vh;
  display: flex;
    align-items: center;

  @include breakpoint(small) {
    padding: 20px;
  }
  
}

.video-box {
  border: 1px solid $first-color;
  padding: 0 30px;
  
  @include breakpoint(small down) {
    padding: 0px;
  }
}


.fb-icon, .google-icon {
  @include mr-s;
}

.forgot-password {
  font-size: 14px;
  @include first-font-semibold;
}

.subscribe-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

// date of birth
.date-birth {
  width: 100%;
  display: inline;
  margin-left: 10px;

  &[type=text], &[type=date] {
    @include mr-xxs;
    margin-left: 0;
    &:last-child {
      @include mr-0;
    }
  }
}

.container-birth {
  display: flex;
  border-radius: 3px;
  height: 3.375rem;
}