// colors
$black: #151515;
$black-hover: lighten($black, 10%);
$dark-grey: #5E5E5E;
$grey-1: #828282;
$grey-2: #E0E0E0;
$light-grey: #F7F7F7;
$white: #ffffff;
$first-color: #fb6396;
$first-color-hover: lighten($first-color, 10%);
$first-color-light: #FFC9DA;
$second-color: #5790DD;
$second-color-light: #80D1FF;
$line-color: #e3e6ea;
$danger: #f91e43;
$success: #41d682;
$box-shadow: rgba(105, 105, 105, 0.14);
$box-shadow-hover: rgba(105, 105, 105, 0.35);

// for third party elements
$grey-paypal: #eee;

// share buttons
$blue-facebook: #1877f2;
$blue-google: #4285f4;
$green-whatsapp:#3FC351;

// z-indexes
$messagebox-z: 3000;
$overlay-z: 2000;
$header-z: 1000;
$addtocart: 10;

// fonts
$first-font: 'Asap', sans-serif;