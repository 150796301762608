#designers-container {
  @include pt-xxs;

    h2 {
      font-size: 60px;
      line-height: 64px;
      @include mb-xxs;
        @include breakpoint(small down) {
          font-size: 50px;
          line-height: 56px;
        }
        &.letters {
          font-size: 40px;
          line-height: 48px;
        }
    }

    a.card:hover {
      background-color: $black;
      border-radius: 50%;
      color: $white;
    }
}

.designer-letters {
  justify-content: space-around;
  padding: 16px 0;

}

.letters-div {
  @include pt-xxs;
  @include pb-xxs;
}

.sticky-letters {
  position: sticky;
  top: 116px;
  background: $white;
  @include mb-s;
  border-bottom: 1px solid $dark-grey;
  @include breakpoint(medium down) {
    top: 60px;
  }
  @include breakpoint(small down) {
    top: 55px;
  }
}

.scrolling-wrapper {
  @media screen and (max-width: 63.9em) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }
}

.card {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.bg-letters-active{
  background-color: $black;
  border-radius: 50%;
  color: $white;

  &.catalog-filter {
    border-radius: 12.5px;
  }
}

.letter-box{
  @media screen and (max-width: 63.9em) {
    width: 100%;
    padding: 24px 24px 0 24px;
  }
}


.designer-list{
  li {
    @include mt-xs;
  }
}

.designer-search {
  max-width: 25vw;
  margin-bottom: 0px;
  border: 0px !important;
  border-bottom: 1px solid !important;
  border-radius: 0px;
  text-align: center;
  @include breakpoint(medium only) {
    max-width: 40vw;
  }

  @include breakpoint(small only) {
    max-width: 80vw;
  }
}

.search-designer-div {
  display: flex;
  justify-content: center;
  @include pt-xxs;
}

.designer-brand {
  font-size: 20px;
  @include first-font-regular;
  text-transform: uppercase;
  text-align: center;
  @include mt-xs;
}