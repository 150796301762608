@mixin box-shadow {
  box-shadow: 0px 2px 20px $box-shadow;
  border-radius: 4px;
}

@mixin box-shadow-hover {
  box-shadow: 0px 2px 20px $box-shadow-hover;
  border-radius: 4px;
}

@mixin first-color {
  color: $first-color;
}

@mixin second-color {
  color: $first-color;
}

@mixin first-font-regular {
  font-family: $first-font;
  font-weight: 400;
}

@mixin first-font-medium {
  font-family: $first-font;
  font-weight: 500;
}

@mixin first-font-semibold {
  font-family: $first-font;
  font-weight: 600;
}

@mixin text-medium {
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin text-small {
  font-size: (7/8) * 1rem;
  line-height: 1.25rem;
}

@mixin text-smaller {
  font-size: (3/4) * 1rem;
  line-height: 1rem;
}