$bottom-inset: env(safe-area-inset-bottom, 0px);

//single product
.product-item {
    position: relative;
    text-align: left;

    &:hover {
        .product-item__img picture:first-child img,
        .product-item__img picture:first-child {
            opacity: 0;
        }
        .product-item__img picture img,
        .product-item__img picture {
            opacity: 1;
        }
    }

    &.relative {
        .add-to-wishlist-catalog {
            display: none;

            @include breakpoint(medium down) {
                display: block;
            }
        }

        &:hover {
            .add-to-wishlist-catalog {
                display: block;
            }
        }
    }
}

.add-to-wishlist-catalog,
.remove-from-wishlist-catalog {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    z-index: 4;
    cursor: pointer;

    img {
        width: 22px;
    }
}

.bounce {
    animation: bounceIn 0.7s;
}

@keyframes bounceIn {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }

    60% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

.close-popup {
    position: absolute;
    right: 16px;
    width: 16px;
    cursor: pointer;

    &.wishlist {
        right: 48px;
        top: 30px;
    }
}

.product-item__img {
    position: relative;

    img + img,
    picture + picture {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 1;
    }
}

.product-item_text {
    @include pv-xxs;

    p {
        @include mb-0;

        @include breakpoint(small down) {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.product-item__price {
    font-size: 18px;
    line-height: 24px;
    @include first-font-medium;

    @include breakpoint(small down) {
        font-size: 16px;
        line-height: 24px;
    }
}

.product-item__price-block {
    @include mt-xxs;

    @include breakpoint(small down) {
        margin-top: 0px;
    }

    &.list {
        display: flex;
        align-items: baseline;

        .iva {
            margin-top: 10px;
            padding-bottom: 5px;
        }
    }

    span.discount {
        color: $dark-grey;
        font-size: 18px;
        line-height: 24px;
        text-transform: inherit;
        @include first-font-regular;

        @include breakpoint(small down) {
            font-size: 14px;
            line-height: 16px;
        }

        &.linedthrough {
            text-decoration: line-through;
        }
    }
}

.buy-now-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include mt-xxs;
}

.product-popup {
    .tax-price-row {
        @include first-font-regular;
        font-size: 20px;
        line-height: 32px;
        color: $dark-grey;
        @include ml-xxs;

        &.linedthrough {
            text-decoration: line-through;
            @include first-font-regular;
        }
    }
}

.product-item__link {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
}

.product-item__table {
    @include breakpoint(small down) {
        margin: 10px 0px;
    }
}

.product-item__description {
    border-top: 1px solid $line-color;
    @include pt-xs;
    @include mt-xs;

    p {
        font-size: 14px;
    }
}

.product-name {
    position: relative;

    .brand {
        font-size: (9/8) * 1rem;
        line-height: 1.5rem;
        text-transform: capitalize;
        @include first-font-regular;

        @include breakpoint(small down) {
            font-size: 1.5rem;
            line-height: 2rem;
            @include mb-0;
        }
    }

    .name {
        font-size: (15/8) * 1rem;
        line-height: 2.5rem;
        @include first-font-regular;
        @include mt-0;
        text-transform: capitalize;

        @include breakpoint(small down) {
            font-size: 1.25rem;
            line-height: 1.5rem;
            @include mb-0;
            @include mt-xxs;
        }
    }

    .price {
        font-size: (15/8) * 1rem;
        line-height: 2.5rem;
        @include first-font-regular;
        @include mb-s;

        @include breakpoint(small down) {
            font-size: 1.25rem;
            line-height: 1.5rem;
            @include mt-xxs;
        }
    }

    &.cart {
        font-size: 1rem;
        line-height: 1.5rem;
        @include margin-0;
    }
}

.color-select,
.cup-select {
    margin-bottom: $xxs;
}

.size-select,
.cup-select {
    @include mb-xxs;

    .option {
        .radio {
            padding: 0 $xxs;
            min-width: 50px;
            @include mb-xxs;
        }
    }
}

.size-select.with-cup-select {
    @include breakpoint(medium down) {
        padding-bottom: calc(224px + #{$bottom-inset} + var(--cup-offset, 0px)) !important;
    }
}

.color-select.with-cup-select {
    @include breakpoint(medium down) {
        padding-bottom: calc(328px + #{$bottom-inset} + var(--cup-offset, 0px) + var(--size-offset, 0px)) !important;
    }
}

#mobile-product-name.with-cup-select {
    @include breakpoint(medium down) {
        padding-bottom: calc(420px + #{$bottom-inset} + var(--cup-offset, 0px) + var(--size-offset, 0px) + var(--color-offset, 0px)) !important;
    }
}

.sizetable {
    display: block;
    font-size: 12px;
    margin-bottom: 24px;
    text-decoration: underline;
}

.color-select,
.size-select,
.cup-select {
    .option {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        input {
            display: none;
        }

        label {
            margin: 0;
            position: relative;
            text-align: center;
        }

        label .label {
            opacity: 0;
            font-size: 12px;
            text-transform: capitalize;
            white-space: nowrap;
        }

        label .radio {
            position: relative;
            border-radius: 6px;
            border: 1px solid $grey-1;

            .check {
                display: none;
                position: absolute;
                top: -11px;
                right: -9px;
                width: 16px;
                height: 16px;
            }
        }

        input:checked + label {
            .label {
                opacity: 1;
            }

            .radio {
                border: 2px solid $black;

                .check {
                    display: block;
                }
            }
        }

        input:disabled + label {
            cursor: not-allowed;
            opacity: 0.7;
        }

        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}

.color-select label .radio {
    display: flex;
    justify-content: center;
    align-items: center;

    .color {
        width: 50px;
        height: 30px;
        border-radius: 4px;
    }
}

.size-select label .radio,
.cup-select label .radio {
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
}

.thumbnail {
    opacity: 0.7;

    &.active {
        opacity: 1;
    }
}

.addtocart {
    @include breakpoint(medium down) {
        margin-bottom: 0;
        position: fixed !important;
        bottom: 0;
        padding-bottom: $bottom-inset;
        left: 0;
        right: 0;
        background-color: $white;
        border-top: 1px solid $line-color;
        z-index: $addtocart;
        box-shadow: -2px 0px 14px 2px $box-shadow;

        .size-select,
        .color-select,
        .cup-select,
        #mobile-product-name {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $white;
            border-top: 1px solid $line-color;
            margin: 0;
            padding: $xs $s calc(128px + #{$bottom-inset});
            z-index: -1;
            transform: translateY(150%);
            transition: transform 0.2s ease-in-out;
            box-shadow: -2px 0px 14px 2px $box-shadow;

            &.open {
                transform: translateY(0%);
            }
            &.size-select, &.color-select, &.cup-select {
                box-shadow: none;
            }
            &.color-select {
                padding-bottom: calc(224px + #{$bottom-inset} + var(--size-offset, 0px));
            }
            &#mobile-product-name {
                padding-bottom: calc(328px + #{$bottom-inset} + var(--size-offset, 0px) + var(--color-offset, 0px));
            }
        }

        .sizetable {
            display: none;
        }

        #select-size-mobile {
            border-radius: 0;
            border: 1px solid $line-color;
            background-color: $white;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include first-font-semibold;

            img {
                transition: transform .2s ease-in-out;
            }

            &.open img {
                transform: rotate3d(1, 0, 0, 180deg);
            }
        }

        button {
            border-radius: 0;
            height: 60px;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.current-qty {
    .qty-input-container {
        @include mt-0;
        @include mb-xxs;
        justify-content: flex-start;
    }

    .not-available {
        @include mv-xs;
    }

    @include breakpoint(large up) {
        height: 50px;
    }

    @include breakpoint(medium down) {
        position: fixed;
        bottom: calc(72px + #{$bottom-inset});
        left: 0;
        right: 0;
        background: $white;
        z-index: -1;
        transform: translateY(0%);
        transition: transform 0.2s ease-in-out;
        padding-left: $s;
        padding-right: $s;

        &:not(.open) {
            transform: translateY(150%);
        }
        
        .qty-input-container {
            margin: 0;
            border: none;
        }

        .not-available {
            text-align: center;
            @include mv-xxs;
        }
    }
}

.add-to-wishlist,
.remove-from-wishlist {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 $xxs;
    height: 100%;
}

.wishlist-buttons .add-to-wishlist,
.remove-from-wishlist {
    border: 1px solid $black;
    border-radius: 20px;

    @include breakpoint(medium down) {
        border: none;
    }
}

.mobile-wishlist-buttons {
    position: absolute;
    top: 8px;
    right: 0;
}

.product-code {
    font-size: 14px;
    color: $grey-1;
    margin-bottom: 16px;
}

.bullet-points {
    .bullet-point {
        color: $black;
        font-size: 14px;
        line-height: 32px;
        padding: $xxs 0;

        img {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $light-grey;
        }
    }
}

.accordion {
    &.product-page {
        a {
            &.accordion-title {
                color: $black;
                font-size: 14px;
                line-height: 16px;
                @include first-font-medium;
                text-transform: uppercase;
                border: none;
                border-bottom: 1px solid $grey-2;
                @include pv-xs;
                @include ph-0;

                &:hover,
                &:focus {
                    background-color: $light-grey;
                }
            }
        }

        .accordion-content {
            border: none;
            @include pv-xs;
            @include ph-0;

            p {
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}

.go-to-detail {
    margin-top: $xs;
    border-top: 1px solid $line-color;
}

.product-container {
    @include pt-s;
}

.product-slider {
    .swiper-pagination-bullet-active {
        height: 3px;
    }

    .swiper-pagination-bullet {
        width: 20px;
        height: 2px;
        border-radius: 0;
    }
}

.tax-price {
    &.single {
        font-size: (9/8) * 1rem;
        @include ml-0;
    }

    &.discount {
        text-decoration: line-through;
        color: $grey-1;
        @include mr-xxs;
    }
}

.tax-price,
.best-taxable {
    @include breakpoint(small down) {
        font-size: 1.75rem;
        line-height: 2rem;
    }
}

.best-taxable {
    @include first-font-medium;
}

.product-brand {
    text-transform: capitalize;
}

.discount-percentage {
    color: $first-color;

    @include breakpoint(small down) {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    &.single-item {
        font-size: 18px;
        line-height: 24px;

        @include breakpoint(small down) {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

.sticker-promo-label {
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 1;

    &.product {
        top: 16px;
    }

    &.wishlist {
        top: 56px;
    }
}

#addtocart-overlay {
    @include breakpoint(large up) {
        display: none;
    }

    @include breakpoint(medium down) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -2;
        background-color: rgba($color: $black, $alpha: .5);

        &:not(.open) {
            display: none;
        }
    }
}