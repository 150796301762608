

#search-bar {
  position: relative;
  height: 40px;
  border-bottom: 1px solid $black;

  [type=text] {
    font-size: 14px;
    width: 100%;
    height: 40px;
    border: none;
    color: $black;
    background-color: transparent;
    @include pl-xs;
    @include pr-l;
  }

  [type=text]::placeholder {
    font-size: 14px;
    color: $black;
    opacity: 1;
  }

  [type=submit] {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1px;
    right: 1px;
    border-radius: 50%;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

#search-mobile {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $overlay-z;
  background-color: rgba($color: $black, $alpha: 0.97);

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    [type=text] {
      font-size: 24px;
      width: 100%;
      height: 48px;
      border: none;
      border-bottom: 1px solid $white;
      border-radius: 0;
      color: $white;
      background-color: transparent;
      padding-right: 32px;
    }
  
    [type=text]::placeholder {
      font-size: 24px;
      color: $white;
      opacity: 1;
    }
  
    [type=submit] {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 14px;
      right: 0;
      border-radius: 50%;
      padding: 0;
  
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

#close-search-mobile {
  position: absolute;
  top: 32px;
  right: 32px;
}