html {
    font-size: 16px;
}

h1 {
    font-size: 8rem;
    line-height: 8.5rem;
    @include first-font-semibold;
}

h2 {
    font-size: 2.5rem;
    line-height: 3rem;
    @include first-font-medium;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

h3 {
    font-size: 1.875rem;
    line-height: 2.5rem;
    @include first-font-medium;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &.regular {
        @include first-font-regular;
    }
}

h4 {
    font-size: 1.25rem;
    line-height: 2rem;
    @include first-font-medium;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: .5rem;

    &.medium {
        @include first-font-medium;
    }
}

.small {
    font-size: (7/8) * 1rem;
    line-height: 1.25rem;
    &.medium {
        @include first-font-medium;
    }
}

.smaller {
    font-size: (3/4) * 1rem;
    line-height: 1rem;
    
    &.medium {
        @include first-font-medium;
    }
}

.medium {
    @include first-font-medium;
}

.semibold {
    @include first-font-semibold;
}