header.sticky {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: $header-z;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: $xxs / 2;
  padding-right: $xxs / 2;
  height: 24px;

  p, strong {
    @include mb-0;
    color: $black;
    font-size: (3/4) * 1rem;
    line-height: 1rem;
  }
}

#header-main {
  background-color: $white;
  padding-bottom: $xxs;
}

.main-logo {
  height: 40px;

  &.mobile {
    height: 40px;
  }
}

.header-icon {
  img {
    width: 24px;
    height: 24px;
  }

  span {
    color: $white;
    position: absolute;
    top: -2px;
    left: 14px;
  }
}

.header-change-type {
  @include padding-xs;
  a {
    color: $grey-1;
    &.active {
      color: $black;
      @include first-font-medium;
    }
  }
  &.left {
    @include ml-xs;
    border-right: 1px solid $line-color;
    a {
      color: $black;
    }
  }
}

.cart-count {
  color: $white;
  font-size: 11px;
  line-height: 16px;
  @include first-font-medium;
  background-color: $first-color;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -2px;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-profile {
  position: relative;
  display: flex;
  align-items: center;

  .dropdown {
    z-index: $header-z + 1;
    display: none;
    position: absolute;
    top: 24px;
    right: 0;
    background-color: $white;
    @include box-shadow;
    @include pv-xxs;
    @include ph-xs;
    min-width: 170px;

    li {
      padding: 4px 8px;

      a,
      input {
        font-size: 14px;
        line-height: 24px;
        border: none;
        background: none;
      }
    }

    li > *,
    li input {
      padding: 0;
      @include first-font-semibold;
      color: $black;
    }

    &:hover {
      display: block;
    }
  }

  &:hover {
    .dropdown {
      display: block;
    }
  }
}

.count-wishlist {
  background-color: $first-color;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -2px;
  left: 16px;
}

.dropdown-mobile {
  z-index: $header-z + 1;
  display: none;
  position: absolute;
  top: 48px;
  right: 0;
  background-color: $white;
  @include box-shadow;
  @include pv-xxs;
  @include ph-xs;

  li {
    padding: 4px 8px;

    a,
    input {
      font-size: 14px;
      line-height: 24px;
      border: none;
      background: none;
    }
  }

  li > *,
  li input {
    padding: 0;
    @include first-font-semibold;
    color: $black;
  }
}
