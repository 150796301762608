@import 'node_modules/foundation-sites/scss/foundation';
@import 'variables';

$breakpoints: (
  small: 0px,
  medium: 640px + 1px,
  large: 1024px + 1px,
  xlarge: 1200px + 1px,
  xxlarge: 1440px + 1px,
);

$flex: true;

@if $flex {
  $global-flexbox: true !global;
}

@include foundation-global-styles;
@if not $flex {
  @include foundation-grid;
}
@else {
  @include foundation-flex-grid;
}
//@include foundation-typography;
@include foundation-forms;
//@include foundation-button;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;

// @if $flex {
//   @include foundation-flex-classes;
// }


@import 'mixins';
@import 'typography';
@import 'spacings';
@import 'general';
@import 'inputs';
@import 'flex';

// components
@import 'components/spinner';
@import 'components/header';
@import 'components/footer';
@import 'components/product';
@import 'components/menu';
@import 'components/cart-summary';
@import 'components/errors';
@import 'components/search';
@import 'components/swiper';
@import 'components/scrollbar';

// pages
@import 'pages/home';
@import 'pages/catalog';
@import 'pages/cart';
@import 'pages/profile';
@import 'pages/cms';
@import 'pages/checkout';
@import 'pages/login';
@import 'pages/contact';
@import 'pages/designers';

@import 'node_modules/intl-tel-input/build/css/intlTelInput';

.iti {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.iti__flag {background-image: url("/skins/current-skin/images/flags/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/skins/current-skin/images/flags/flags@2x.png");}
}

// fonts
@import '../fonts/asap/asap.css';

.loading {
    position: relative;
    pointer-events: none;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: $white, $alpha: .5);
    }
}