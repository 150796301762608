
.section-popup {
  display: flex;
  justify-content: center;
}

.popup {
  width: 80%;
  position: fixed;

  @media screen and (max-width: 39.9375em) {

    top: 150px;

  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {

    top: 120px;

  }

  @media screen and (min-width: 64em) {

    top: 170px;

  }

  z-index: 100;
}

.contact__form-container {
  /*
  background-color: $white;
  */
  border-radius: 8px;
  position: relative;
  z-index: 1;
  /*
  box-shadow: 0 2px 15px rgba(67, 73, 108, 0.18);
  */

  &.form-landing
  {
    background-color: $light-grey;
    padding: 30px;
    border-radius: 0px;
  }
}

.contact_check-form{
  display:table;
}


[type='text'], [type='email'], [type='password'], [type='tel'], [type=date], textarea{
  box-shadow: none;
  border: 1px solid $dark-grey;
  border-radius: 3px;
  color: $black;

  &:focus{
    border: 1px solid $first-color;
    box-shadow: none;
  }

}

//form desginer

.reveal-overlay {
  z-index: 110;

}