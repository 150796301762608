.breadcrumb {
    @include text-small;
    li {
        display: inline-block;
    }
}

#product-popup {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($dark-grey, 0.7);
    z-index: $overlay-z + 10;

    .addtocart-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .addtocart-container {
        padding: 0 $s $s $s;
        background-color: $white;
        border-radius: 20px;
        height: max-content;
        width: 60%;

        @media screen and (min-width: 64em) and (max-width: 110em) {
            width: 80%;
        }

        @media screen and (min-width: 110em) {
            width: 40%;
        }
        .addtocart {
            margin-bottom: 0;
        }
    }
}

#catalog-container {
    position: relative;

    .loader {
        display: none;
        color: $grey-1;
        position: absolute;
        top: calc(50% - 1em);
        left: calc(50% - 1em);
        z-index: $overlay-z;
    }

    #layout-options,
    #catalog-wrapper {
        opacity: 1;
        transition: opacity 0.4s linear;
    }
}

#catalog-container.loading {
    min-height: 18em;

    .loader {
        display: block;
    }

    #layout-options,
    #catalog-wrapper {
        display: none;
        opacity: 0;
    }
}

#layout-options {
    display: flex;
}

#grid-option,
#list-option {
    display: none;

    + label img.enabled {
        display: none;
    }
    + label img.disabled {
        display: block;
    }

    &:checked {
        + label img.enabled {
            display: block;
        }
        + label img.disabled {
            display: none;
        }
    }
}

.no-products-found,
.no-search-found {
    text-align: center;
    padding: 56px;

    h1 {
        font-size: 56px;
        line-height: 64px;
        @include first-font-medium;
    }
    @include breakpoint(medium down) {
        h1 {
            font-size: 40px;
            line-height: 56px;
            @include first-font-medium;
        }
    }
    @include breakpoint(small down) {
        margin: 56px 0 72px;
        padding: 32px;
    }
    &.catalog {
        margin: 2vw 0;
    }
}

.no-product-related {
    margin-top: 80px;
}

.no-result {
    @include first-font-regular;
    span {
        @include first-font-regular;

        &#search-keyword {
            @include first-font-medium;
        }
    }
    @include breakpoint(medium down) {
        font-size: 20px;
        line-height: 24px;
    }
}

.link-no-result {
    color: $first-color;
    @include first-font-regular;
    text-transform: lowercase;
}

#catalog-container {
    position: relative;

    .catalog-spinner {
        display: none;
        z-index: 5;
        position: absolute;
        top: 0;
        width: 100%;
        height: 2px;

        &:after {
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation: indeterminate-bar 2s linear infinite;
            animation: indeterminate-bar 2s linear infinite;
            width: 100%;
            height: 2px;
            background-color: $first-color;
            content: "";
            display: block;
        }
    }

    &.catalog-loading {
        #catalog-wrapper {
            opacity: 0.4;
        }

        .catalog-spinner {
            display: block;
        }
    }
}

.catalog-top {
    @include mb-xs;
}

.catalog-top-filters {
    position: relative;
    display: flex;
    align-items: baseline;
}

.catalog-top-options {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    @include breakpoint(medium down) {
        justify-content: space-between;
        @include mb-xs;
    }
}

.catalog-title {
    border-bottom: 1px solid $line-color;
    @include pv-xs;
    font-size: 30px;
    line-height: 32px;
    @include margin-0;
    @include first-font-medium;

    @include breakpoint(medium down) {
        font-size: 20px;
        line-height: 24px;
    }
}

.catalog-filter-wrapper {
    padding: 30px 0;
}

.catalog-sort {
    display: flex;
    align-items: center;

    span {
        @include first-font-semibold;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.05em;
        margin: 3px 0 0 10px;
    }
}

.catalog-sort__list {
    list-style: none;

    li {
        display: inline-block;
        margin-right: 10px;
    }

    .catalog-sort__svg {
        width: 20px;
    }

    a {
        display: flex;
        align-items: center;
        border: 1px solid $black;
        padding: 7px;

        &:hover,
        &.active {
            background-color: $black;

            .catalog-sort__svg path {
                fill: $white;
            }
        }
    }
}

.catalog-filter__btn {
    background-color: $first-color;
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.05em;
    width: 200px;
    padding: 10px 14px 8px;
    text-align: left;
    position: relative;
    @include breakpoint(small down) {
        width: 100%;
        margin-top: 20px;
    }

    &:focus {
        outline: none;
    }

    img {
        position: absolute;
        width: 13px;
        right: 11px;
        top: 11px;

        &.hide {
            display: none;
        }
    }
}

.layout-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 10px;

    @media screen and (max-width: 63.9375em) {
        //justify-content: start;
        padding-top: 5px;
    }

    span {
        @include first-font-semibold;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.05em;
        margin: 3px 10px 0 0;
    }
}

.layout-options__item {
    display: inline-block;
    margin-left: 14px;

    svg {
        width: 20px;
    }

    path {
        fill: $black;
    }

    input {
        display: none;

        &:checked + label,
        + label:hover {
            /* background-color: $black; */

            path {
                fill: white;
            }
        }
    }

    label {
        margin: 0;
        display: flex;
        align-items: center;
        border: 1px solid $black;
        padding: 7px;
    }
}

.h1-tag {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    @include first-font-semibold;
    @include breakpoint(medium down) {
        font-size: 14px;
    }
}

.filters-row {
    display: flex;
    @media screen and (max-width: 63.9375em) {
        flex-direction: column;
    }
}

.filters-wrapper {
    @include pb-m;
    border-top: 1px solid $light-grey;
}

.filters-title {
    @include first-font-semibold;
    font-size: 10px;
    letter-spacing: 0.1em;
    text-align: center;
    margin: 0 0 20px;
    @include breakpoint(medium down) {
        margin: 25px 0 10px;
    }
}

#toggleFilterSmall .filters-active::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $first-color;
    position: absolute;
    bottom: 16px;
    right: -8px;
}

.catalog-filter__img {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 5px;
    cursor: pointer;

    img {
        width: 10px;

        &.hide {
            display: none;
        }
    }
}

.subfilters-list {
    margin-left: 25px;
    display: none;
}

.filters-checkbox {
    max-height: 255px;
    overflow: hidden;

    &.open {
        max-height: none;
    }
}

.filters-checkbox-btn {
    border-top: 1px solid $line-color;
    max-width: 350px;
    margin: 25px auto 0;
    cursor: pointer;
    text-align: center;

    img {
        width: 10px;

        &.hide {
            display: none;
        }
    }
}

.current-filter {
    @include first-font-regular;
    display: block;
    font-size: 10px;
    position: relative;
    font-size: 16px;
    color: $black;
    margin: 0 16px 0 0;
    padding: 8px;

    img {
        width: 10px;
        height: 10px;
        position: relative;
        top: -1px;
        margin: 0 5px;
    }

    &.remove-all {
        background-color: $light-grey;
        border-radius: 8px;
    }
}

.color-checkbox {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    box-shadow: 1px 0px 3px 0px $box-shadow;
}

.current-filter-name {
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-left: 10px;
    /* margin-left: 20px; */
    @include first-font-regular;
    color: $first-color;
}

.remove-current-filter {
    display: block;
    border: 1px solid $first-color;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px 3px;
    margin: 10px 20px 0 0;
    @include first-font-regular;
}

.filter-separator {
    border-top: 1px solid $line-color;
    margin-top: 28px;
    padding-top: 25px;
    @include breakpoint(small down) {
        padding-top: 5px;
    }
}

.catalog-line {
    border: none;
    border-bottom: 1px solid $line-color;
    margin-bottom: 10px;
}

.promo-tag {
    @include first-font-semibold;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $first-color;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.1em;
    padding: 5px 20px 4px;
    z-index: 10;
}

.filters-trigger__minus {
    display: none;
}

.open-filter {
    .filters-trigger__plus {
        display: none;
    }

    .filters-trigger__minus {
        display: block;
    }
}

@keyframes indeterminate-bar {
    0% {
        transform: translate(-50%) scaleX(0);
    }
    50% {
        transform: translate(0%) scaleX(0.3);
    }
    100% {
        transform: translate(50%) scaleX(0);
    }
}

.filter-button {
    height: 40px;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 15px !important;
}

#arrow-filter {
    transition: 1s;
}

.column-button {
    display: flex;
    justify-content: flex-start;
    padding-top: 5px;
}

.rotated {
    transform: rotate(180deg);
}

#catalog-container {
    .catalog-spinner {
        display: none;
        z-index: 10000;
        position: fixed;
        top: 150px;
        width: 100%;
        height: 3px;

        @include breakpoint(small down) {
            top: 0;
        }

        &:after {
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation: indeterminate-bar 2s linear infinite;
            animation: indeterminate-bar 2s linear infinite;
            width: 100%;
            height: 2px;
            background-color: $secondary-color;
            content: "";
            display: block;
        }
    }

    &.catalog-loading {
        .catalog-spinner {
            display: block;
        }

        &:before {
            content: "";
            display: block;
            position: fixed;
            z-index: 10000;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(white, 0.5);
        }
    }

    .catalog-top {
        position: relative;

        > *:not(:last-child) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        > *:last-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.options-view {
    width: max-content;
    @include mr-xs;

    .name {
        cursor: pointer;

        .text {
            margin-right: $xxs;
            text-transform: uppercase;
        }

        img {
            transition: transform 0.2s ease-in-out;
        }
    }

    .dropdown {
        display: none;
        z-index: $header-z - 100;
        position: absolute;
        left: 0;
        right: 0;
        padding: $xxs $xs;
        background-color: $white;
        border-bottom: 1px solid $line-color;
        box-shadow: 0px 9px 10px $box-shadow;
    }

    &.open {
        .dropdown {
            display: block;
        }

        .name img {
            transform: rotate(180deg);
        }
    }
}

#sort-options .dropdown {
    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    li {
        padding: $xxs $xs;
    }

    li:not(:last-child) {
        margin-right: $xs;
    }
}

#mobile-sort .menu-container {
    li {
        padding: $xxs $xs $xxs $m;
    }
}

#filter-options {
    .dropdown {
        min-height: 400px;
        padding: $xxs $xs;
    }

    .dropdown > ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        min-height: max-content;
    }

    .dropdown .controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: $m;
        padding-right: $xs;
    }

    .filter {
        width: var(--max-width);
        margin-right: $xs;

        .title {
            font-size: 16px;
            line-height: 24px;
            margin: $xxs 0;
        }

        ul {
            padding-left: $xs;
        }

        .count {
            color: $grey-1;
        }
    }
}

#mobile-filters {
    .menu-container {
        padding-bottom: 0;
    }

    .menu-container > ul {
        max-height: calc(100vh - #{$xxl});
        overflow-y: scroll;
        padding-bottom: $xs;
        margin-top: $xxl;
    }

    .filter {
        padding-left: $xs;

        .title {
            font-size: 16px;
            line-height: 24px;
            margin: $xxs 0;
        }

        ul {
            padding-left: $xs;
        }

        .count {
            color: $grey-1;
        }
    }

    .controls {
        position: absolute;
        top: 0;
        left: 0;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .remove-all-filters {
            background-color: transparent;
            padding: 0;
            margin-left: $xs;
        }
    }
}

#sort-options .dropdown,
#mobile-sort ul,
#filter-options .filter ul,
#mobile-filters .filter ul {
    li a {
        position: relative;
        padding-left: $xxs;
        font-size: 14px;
        line-height: 24px;
    }

    li a::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        border: 1px solid $line-color;
        top: 0;
        left: -16px;
    }

    li a.selected::after {
        content: "✓";
        color: $white;
        font-size: 16px;
        text-align: center;
        line-height: 18px;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: -16px;
        background-color: $first-color;
    }
}

#mobile-filters-toggle {
    padding-right: $xs;
    border-right: 1px solid $line-color;
}

#mobile-sort-toggle {
    padding-left: $xs;
}

.visible-active {
    display: none !important;
}
.hidden-active {
    display: inline;
}

.contact-no-quantity {
    width: 100%;
    font-size: 15px;
    margin-top: 20px;
}

li.active > a {
    /* background: white;
  color:#fff; */
    .visible-active {
        display: inline !important;
    }
    .hidden-active {
        display: none !important;
    }
}

.catalog-products {
    transition: 1s;
}

.filters-hide {
    max-width: 0px;
    opacity: 0;
    padding: 0;
}

.filter-selector {
    margin-right: 56px;
}

.filter-tag:not(.mobile) {
    position: relative;
    transition-delay: 0.4s;
    transition-timing-function: linear;
    transition-duration: 0.1s;

    &.open {
        border-radius: 8px 8px 0 0;
        transition-delay: 0s;
    }

    .filter-name {
        @include first-font-regular;
        padding: 4px 8px;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;

        .arrow-filter-indicator {
            width: 14px;
            margin-left: 10px;
        }
    }

    &::after {
        content: "";
        border: 2px solid $white;
        position: absolute;
        bottom: 0;
        left: 1px;
        width: calc(100% - 2px);
        opacity: 0;
        z-index: 2; // above dropdown
        transition-delay: 0.4s;
    }
}

.filter-tag.open::after {
    opacity: 1;
    transition-delay: 0s;
}

.filter-dropdown {
    z-index: 100 - 1; // Below header, above everything else
    position: absolute;
    width: 220px;
    background: $white;
    border-left: 1px solid $dark-grey;
    border-right: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
    left: -1px;
    border-radius: 0 0 8px 8px;
    padding-bottom: 5px;

    .scrolling-filter {
        padding: 8px;
        overflow-y: auto;

        li {
            padding: 8px;
        }
    }
}

.filters-title.mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
        padding: 8px;
        @include first-font-semibold;
    }

    .remove-all {
        color: $black;
        background-color: $light-grey;
        border-radius: 8px;
        margin-right: 50px;
        border-radius: 5px;
        display: block;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        padding: 8px;

        > * {
            font-size: 16px;
            @include first-font-regular;
        }

        &:active {
            background-color: $dark-grey;
        }
    }
}

#offCanvasFilters {
    height: calc(100vh - 80px);
}

.filter-name.mobile {
    background-color: $white;
    border-bottom: 1px solid $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @include first-font-regular;
    font-size: 16px;
    padding: 16px;

    .number-select {
        border: none;
        font-size: 12px;
        line-height: 12px;
        margin-left: 30px;
    }

    .arrow {
        width: 16px;
    }
}

.filter-tag.mobile {
    border: none;

    &.open {
        .arrow {
            transform: rotate(180deg);
        }
    }

    .filter-dropdown {
        border: none;
        width: 100%;
        padding-bottom: 0;

        ul {
            padding: 0;
        }

        li {
            border: none;
            border-bottom: 1px solid $light-grey;
            background-color: $grey-paypal;
            padding: 16px;

            a {
                width: 100%;
                display: inline-block;
                line-height: 24px;
                font-size: 16px;
            }
        }

        &.sort-options {
            padding: 16px 0 8px;
            margin-bottom: 16px;
        }
    }
}

.options-toggle {
    background-color: $white;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow {
        width: 16px;
    }

    &.open {
        .arrow {
            transform: rotate(180deg);
        }
    }

    .title {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px;
        @include first-font-semibold;
    }
}

.sort-dropdown.mobile {
    .options {
        .option {
            background-color: $grey-paypal;
            border-bottom: 1px solid $light-grey;
            font-size: 16px;
            width: 100%;
            padding: 16px;

            a {
                display: inline-block;
                width: 100%;
            }
        }

        .option {
            .far:not(.active) {
                display: inline-block;
            }

            .far.active {
                display: none;
            }
        }

        .option.selected {
            .far:not(.active) {
                display: none;
            }

            .far.active {
                display: inline-block;
            }
        }
    }
}

.filters {
    transition: 1s;

    .filter-tag {
        padding: 16px 0;
        position: relative;
        border-bottom: 1px solid $dark-grey;

        @media screen and (min-width: 40em) and (max-width: 63.9375em) {
            min-width: 33%;
        }

        > ul {
            li > a > span {
                text-transform: capitalize;
                display: block;
                padding: 4px 10px;
                color: black;
                background: white;
                margin: 2px 0;

                &:hover {
                    background: darken(grey, 5%) !important;
                    color: #fff;
                    text-decoration: none;
                }

                .visible-active {
                    display: none;
                }
                .hidden-active {
                    display: inline;
                }
            }

            li.active > a {
                /* background: white; */
                /* color:#fff; */
                .visible-active {
                    display: inline;
                }
                .hidden-active {
                    display: none;
                }
            }
        }
    }

    .filter-sublist {
        > li.active > .toggle-subfilter {
            color: white;
        }

        > li > .toggle-subfilter {
            position: absolute;
            left: 5px;
            top: 5px;
            z-index: 2;
            display: block;
            width: 16px;
            height: 16px;
            text-align: center;
            .fa-plus {
                display: inline-block;
            }
            .fa-minus {
                display: none;
            }
        }

        > li > .toggle-subfilter + a {
            padding-left: 24px;
        }

        > li > a {
            font-weight: bold;
            text-transform: uppercase;

            > li {
                text-transform: capitalize;
            }
        }

        > li.open > ul {
            display: block;
        }
    }

    .filter-name {
        display: flex;
        justify-content: space-between;
        @include breakpoint(medium down) {
            justify-content: initial;
        }
        align-items: center;
        text-transform: uppercase;
        font-size: 18px;
        margin: 0;
        line-height: 32px;
        cursor: pointer;
        width: fit-content;
        @include first-font-regular;
        width: 100%;
    }

    &.open {
        .filter-name {
            border: 1px solid $black !important;
            span.fa {
                filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
                transform: rotate(-180deg);
            }
        }
    }
}

.filter-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.filter-order {
    display: flex;
    justify-content: flex-end;
    @include breakpoint(medium down) {
        justify-content: center;
    }
}

//catalog banner catalog

.catalog-banner-img {
    height: 250px;
}

.layout-grid-img {
    width: 18px;
}

.number-select {
    border-bottom: 1px solid $light-grey;
    @include first-font-regular;
    padding: 7px 0px;
}

.fa-check:before {
    content: "\f00c";
    color: $white;
    text-shadow: 0px 0px 1px $dark-grey;
}

.img-search-error {
    width: 31px;
    margin: 9px 20px;
}

//pagination

.pagination li {
    padding-bottom: 0;
    border-radius: 50px;

    @include breakpoint(small down) {
        display: inline-block;
    }

    &:hover {
        border-radius: 50px;
    }

    &:active {
        border: 2px solid $first-color;
    }

    img {
        width: 7px;
    }
}

ul.pagination li a {
    border-radius: 50%;
}

ul.pagination li:not(.pagination-arrow) a {
    @include breakpoint(small down) {
        width: 31px;
        height: 31px;
    }
}

ul.pagination li:not(.pagination-arrow).active a {
    color: $white;
    border: 2px solid $white;
    background-color: $first-color;
}

.row-filter {
    justify-content: space-between;

    @include breakpoint(medium down) {
        margin-top: 15px;
    }
}

#toggle-filters-button {
    margin-bottom: 5px;
}

.filter-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.filter-order {
    display: flex;
    justify-content: flex-end;
    @include breakpoint(medium down) {
        justify-content: center;
    }
}

.container-catalog-option {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filters.filters-dropdown::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}

.filters.filters-dropdown::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(122, 122, 122);
    outline: 1px solid slategrey;
}

.filters.filters-dropdown::-webkit-scrollbar-track {
    box-shadow: inset 0 0 100px rgba(245, 245, 245, 1);
    border-radius: 10px;
}

img.arrow.filter-toggle {
    width: 20px;
    height: 16px;
    @include ml-xxs;
}

.filter-toggle {
    @include first-font-semibold;
    color: $black;
}

#toggleFilterLarge {
    @include ml-xs;
    cursor: pointer;
}

.scrolling-filter {
    max-height: 200px;
    overflow-y: scroll;

    &.mobile {
        max-height: none;
    }
}

.filter-sublist-title {
    font-size: 16px;
    @include first-font-semibold;
    text-transform: uppercase;
    @include mt-xxs;
}

ul.filter-sublist {
    @include ml-xs;
}

.filter-sublist > li > ul {
    @include ml-xs;
    @include mt-xs;
}

.filter-sublist-category {
    border-bottom: 1px solid $light-grey;
    white-space: nowrap;
    &:last-child {
        border-bottom: none;
    }
}

.filter-btn {
    span {
        @include first-font-semibold;
    }
}

.rotate-180 {
    transform: rotate(180deg);
}

.close-filter-mobile {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 20;
    width: 24px;
    height: 24px;
}

.category-title-breadcrumb {
    font-size: 30px;
    line-height: 40px;
    @include first-font-regular;
    text-transform: uppercase;
    margin: 16px 0;
}

.description-list {
    h1,
    h2,
    h3,
    h4,
    p,
    strong {
        font-size: 14px;
        line-height: 24px;
        @include first-font-regular;
    }
}

//search result
.search-result {
    font-size: 25px;
    color: $black;
    text-transform: uppercase;
    @include first-font-semibold;
}

.number-results {
    color: $dark-grey;
    font-size: 16px;
    text-transform: initial;
}

#results-search {
    color: $first-color;
    @include first-font-semibold;
    text-transform: initial;
}

.catalog-subtitle {
    font-size: 20px;
    line-height: 24px;
}

.catalog-text {
    p {
        font-size: 14px;
        line-height: 24px;
    }
}
