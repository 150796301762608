.checkout-wrapper {
  margin: 95px 0 70px;
}

.checkout-title {
  @include first-font-regular;
}

.checkout-active {
  border-bottom: 1px solid $line-color;
  padding: 15px 0 7px;
  margin: 0 0 35px;
  color: $black;
  font-size: 30px;
}

.checkout-step {
  padding: 15px 0 7px 40px;
  position: relative;
  letter-spacing: .01em;
  border-bottom: 1px solid $line-color;
  color: $black;
  font-size: 25px;

    &.active {
      margin: 0 0 25px;
      color: $black;
    }

    &::before {
      @include first-font-regular;
      background-color: white;
      width: 30px;
      height: 30px;
      border-radius: 16px;
      position: absolute;
      border: 1px solid $first-color;
      left: 0;
      top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 14px;
      color: $black;
      border: 1px solid $first-color;
    }

    &.first::before {
      content: "1";
    }
    &.second::before {
      content: "2";
    }
    &.third::before {
      content: "3";
    }

    &.active::before {
      color: $black;
      background-color: $first-color;
      color: $white;
    }
}

.checkout-buttons {
  margin: 0 0 $m;
}

.payment-table {
  display: flex;
  padding-bottom: 20px;
}

.payment-table {
  p {
    margin-bottom: 4px;
  }
}

p.orders-details {
  font-size: 14px;
  margin-bottom: 6px;

}

.title-line-container {
  display: inline-block;
  position: relative;
}


.title-cart {
  font-size: 20px;
  line-height: 24px;
  @include first-font-regular;
  position: relative;
  text-transform: capitalize;
  color: $grey-1;
  @include mb-0;
  &.over-title {
      z-index: 2;
  } 
  &.title-active {
      @include first-font-medium;
      color: $black;
  }
  
  @include breakpoint(small down) {
      font-size: 16px;
      line-height: 24px;
  }
}

.first-color-line {
  height: 3px;
  background-color: $first-color;
  position: absolute;
  top: 37px;
  width: 120px;
  left: 0px;
  z-index: 0;
  
  &.small {
    width: 120px;
    left: 40px;
    bottom: 0;
  }

  &.line-cart {
    left: 0px;

    @include breakpoint(medium down) {
      width: 90px;
    }
  }
}