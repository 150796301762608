.cart-summary {
  background-color: $light-grey;
  @include padding-m;

  @include breakpoint(medium down) {
    background-color: $white;
    @include padding-xxs;
  }
}

.cart-summary__line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 4px 0;

    span {
      @include first-font-semibold;
      text-transform: uppercase;
      font-size: (7/8) * 1rem;
      letter-spacing: 0.05em;
    }

    .label {
      font-size: (3/4) * 1rem;
    }
}

.cart-summary__separator {
  border: none;
  border-top: 1px solid $line-color;
  margin: 8px 0 10px;
}

.btn-update{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    @include ml-xxs;
    padding: 0px;
    & img{
      width: 17px;
    }
}

.margin-top-info-cart{
  @media (min-width: 481px) and (max-width: 767px) {
   margin-top: 65px;
  }
}