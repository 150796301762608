[data-scrollbar] {
    position: relative;
    width: 100%;
    overflow: hidden;

    [data-container] {
        position: absolute;
        overflow-y: scroll;
        top: 0;
        bottom: 0;
        left: 0;
        right: -17px;

        > * {
        padding-right: 17px;
        }
    }

    .scrollbar {
        background-color: lightgrey;
        border-radius: 17px;
        width: 9px;
        position: absolute;
        top: 0;
        right: 0;
    }
}