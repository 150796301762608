//Subscribe
.list {
    display: flex;
    flex-wrap: wrap;
}

.list-item {
    display: flex;
    padding: 0.5em;
    width: 100%;
}

.list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.list-content p {
    flex: 1 0 auto;
}

.label-subscribe {
    position: absolute;
    top: 22%;
    text-align: center;
    width: 100%;
    h2 {
        font-size: 32px;
        color: $white;
        text-shadow: 0px 2px 5px $black;
    }

    h3{
        font-size: 24px;
        color: $white;
        text-shadow: 0px 2px 5px $black;
    }

    &.second {
        top: 29%;
    }

    p{
        text-shadow: 0 2px 5px $black;
        color: white;
        margin: 20px 28px;
        font-size: 18px;
    }
}


#main-slider {
    width: 100%;
    height: calc(100vh - 112px);
    @include breakpoint (medium down) {
        height: calc(100vh - 56px)
      }
}


.swiper-slide {
    &.single-image{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.swiper-pagination-bullet {
    background-color: $black;
}

.swiper-new-arrivals, .swiper-new-arrivals-popup, .swiper-related, .swiper-new-products {
    .swiper-button-prev:after{
        content: url(/skins/current-skin/images/icons/arrow-left-black.svg)!important;
    }

    .swiper-button-next:after{
        content: url(/skins/current-skin/images/icons/arrow-right-black.svg)!important;
    }
}

.paypal-banner > * {
    max-width: 100vw !important;
}

#instafeed {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: $xxs;
    width: 100%;

    @include breakpoint(medium down) {
        grid-template-columns: repeat(2, 1fr);
    }

    .instagram-image {
        display: block;
        width: 100%;
        padding-top: 100%;
        background-image: var(--bg-image);
        background-position: center;
        background-size: cover;
    }
}