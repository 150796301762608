#cart-container,
#checkout-container {
    @include pt-m;

    @include breakpoint(medium down) {
        padding-top: 0px;
    }
}
.cart-title {
    color: $black;
    font-size: 30px;
    line-height: 32px;
    border-bottom: 1px solid $grey-2;
    @include pb-xxs;
    @include mt-0;
    @include mb-0;
    @include first-font-medium;
    @include breakpoint(small down) {
        @include mv-m;
    }

    &.summary {
        font-size: 20px;
        line-height: 24px;
    }
}

.cart-product {
    border-bottom: 1px solid $line-color;
    @include mb-s;
}

.cart-product__subtitle {
    font-size: 16px;
    display: block;
    margin: 2px 0;
}

.btn-cart-remove,
.btn-update {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    @include ml-xxs;
    border: 1px solid $grey-2;
    border-radius: 50px;

    img {
        width: 19px;
    }
}

.cart-product__buttons {
    text-align: center;
    padding: 35px 0;

    button {
        @include first-font-semibold;
        background-color: $black;
        border: 1px solid $black;
        color: $white;
        width: 182px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        padding: 8px 0 8px;
        position: relative;
        margin: 0 8px;

        &.-light {
            color: $black;
            background-color: $white;
        }

        &:active {
            top: 1px;
        }
    }
}

.cart-product__prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding-bottom: 30px;

    span {
        @include first-font-semibold;
        display: block;
    }

    small {
        font-weight: bold;
        text-decoration: line-through;
        color: $dark-gray;
    }
}

.cart-product__price-label {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.05em;
}

.cart-product__price-big {
    font-size: 22px;
    margin-top: -5px;
}

.cart-resume {
    @include ml-xxl;
    @include breakpoint(medium down) {
        margin-left: 0;
    }
}

.cart-resume__top {
    p {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0px;

        &.right {
            text-align: right;
            @include first-font-regular;

            &.total {
                @include breakpoint(medium down) {
                    @include first-font-medium;
                }
            }
        }

        &.-light {
            color: $dark-grey;
        }
    }

    &.-right {
        text-align: left;

        p {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.coupon-input {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    > * {
        width: 50%;
    }

    input {
        margin: 0 10px 0 0;
        height: 40px;
        box-shadow: none;
        border-color: $line-color;
        text-align: center;
        font-weight: bold;
        letter-spacing: 0.04em;
        font-size: 14px;
        padding: 8px 8px 6px;
        color: $black;
    }
}

.coupon-label {
    font-size: 16px;
    margin-bottom: 4px;
    transition: all 0.15s ease-in-out;
    top: 6px;
    left: 14px;
    text-transform: uppercase;
    color: $black;
    font-size: 14px;
}

.country-select {
    @include first-font-semibold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 12px;
    height: 34px;
    padding-left: 11px;
    border-color: $line-color;
}

.cart-total {
    @include pv-xs;
    text-align: left;

    span {
        @include first-font-medium;
        display: block;
    }
}

.cart-total__label {
    font-size: 20px;
    line-height: 24px;
    @include first-font-medium;
    text-align: left;
    text-transform: uppercase;
}

.cart-total__price {
    font-size: 20px;
    line-height: 24px;
    @include first-font-medium;
    text-align: right;
}

.select-carrier {
    margin: 0 0 15px;
}

/*.cart-resume__space {
  margin: 0 0 15px;
}*/

.cart-dimension {
    font-size: 16px;
    margin: 2px 0;
}
.cart-update .qty-input-container {
    @include mt-0;
}

.description-product_cart {
    display: flex;
    align-items: center;
}

.shareWishlistButton {
    text-align: left;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-content: center;
    > * {
        margin-bottom: 0px;
    }
}

.share-button {
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.whatsapp {
        background: $green-whatsapp;
    }
    &.mail {
        background: $first-color;
    }
    &.facebook {
        background: $blue-facebook;
        position: relative;
        top: -1px;

        > a {
            > img {
                transform: scale(1.1);
                margin: 0;
            }
        }
    }
}

hr.share-wishlist {
    border-top: 1px solid $line-color;
}

.reveal {
    &.wishlist {
        @include breakpoint(medium down) {
            margin: 0 auto;
            width: 90%;
            height: auto;
            min-height: auto;
            top: 20% !important;
        }
    }
}

.share-button-div {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.share-button {
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    > a > img {
        width: 24px;
        margin-bottom: 2px;
    }
}

.popupShareLink {
    border: 1px solid $line-color;
    padding: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: none;
}

.popupShareLinkButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $first-color;
    color: $white;
}

.empty-cart {
    text-align: right;

    @include breakpoint(medium down) {
        text-align: left;
    }
}

.cart-product__title {
    font-size: 14px;
    line-height: 24px;
    @include first-font-medium;
    @include margin-0;
    @include mt-xxs;
}

.euro {
    @include first-font-semibold;
}

.cart-product-label {
    text-align: right;
}

.cart-summary-mobile {
    background: $light-grey;
    .summary-item {
        text-align: center;
        padding: 20px 0;
    }
    .summary-title {
        font-size: 16px;
        line-height: 24px;
        @include first-font-regular;
        @include mb-xxs;

        @include breakpoint(medium down) {
            font-size: 14px;
            line-height: 24px;

            &.total {
                @include first-font-medium;
            }
        }
    }
    .summary-value {
        @include first-font-medium;
        font-size: 1.25rem;
        line-height: 2rem;
        &.right {
            font-size: (7/8) * 1rem;
            line-height: 1.25rem;
        }
    }
}

.choose-book-type {
    background: $light-grey !important;
    border-radius: 4px;
    color: #828282;
    border: 1px solid #e0e0e0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 16px;
}

.choose-book-type-container:after {
    content: url(/skins/current-skin/images/icons/arrow-down-grey.svg);
    width: 12px;
    height: auto;
    position: absolute;
    right: 10px;
    top: 21px;
}

.message-points {
    background-color: $first-color;
    width: 100%;
    padding: 10px;
    margin-bottom: 32px;
    p {
        color: $black;
        text-align: center;
        @include first-font-semibold;
    }
}

.points-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 24px;
    border-top: 1px solid $dark-grey;
    @include pt-xs;
    span {
        @include first-font-semibold;
        font-size: 16px;
        text-align: left;
        text-transform: uppercase;
    }

    @include breakpoint(medium down) {
        border: none;
    }
}

.points-div {
    padding-bottom: 16px;
}

.burn-coupon-form {
    @include breakpoint(medium down) {
        border-bottom: 1px solid $line-color;
    }

    label {
        @include first-font-semibold;
    }
}

.code-product {
    font-size: 12px;
    line-height: 16px;
    @include first-font-regular;
    color: $dark-grey;
}

.i-number-btn {
    border: 1px solid $grey-2;
    display: flex;
    min-width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: $light-grey;
    border-radius: 2px;

    img {
        width: 14px;
    }
}

.qty-input-container {
    display: flex;
    @include mt-xs;
    align-items: initial;
    justify-content: flex-end;
    position: relative;

    &.cart {
        justify-content: center;
    }

    &.container-small {
        @include mt-s;
    }
}

.quantity-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-2;
    border-radius: 50px;

    .btn-minus,
    .btn-plus {
        cursor: pointer;
        display: flex;
        height: 40px;
        width: 30px;
        color: $grey-2;
        padding: 0 $xxs;
        display: flex;
        align-items: center;
    }

    .i-number {
        margin: 0;
        height: 40px;
        box-shadow: none;
        width: 70px;
        text-align: center;
        border: none;
        background-color: $white;
        cursor: default;
    }
}

.cart-table {
    thead,
    tbody {
        background-color: $white;
        border: none;
        &.border-bottom {
            border-bottom: 1px solid $light-grey;
        }
    }
    th {
        font-size: 14px;
        line-height: 24px;
        @include first-font-medium;
        padding: 0 $xxs;
    }

    td {
        @include pt-xxs;
        @include pb-0;
    }

    .price-cart {
        @include first-font-regular;
        font-size: 14px;
        line-height: 24px;
        @include first-font-medium;
        text-align: right;
    }
}

.price-cart {
    &.main {
        @include first-font-medium;
    }
}

.cart-update-container {
    align-items: flex-end;
    display: inline-grid;

    @media screen and (min-width: 50em) and (max-width: 64.0625em) {
        min-width: 100%;
    }
}
