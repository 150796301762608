.content-404 {
  margin: 9vw 0 10vw;
  @include breakpoint(small down) {
    margin: 55px 0 70px;
  }

    h1 {
      @include first-font-regular;
      font-size: 100px;
      line-height: 116px;
      letter-spacing: 0.05em;
      @include mb-s;
    }

    h2 {
      font-size: 30px;
      letter-spacing: 0.01em;
      margin-bottom: 0;
    }
}

.header-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: $xxs 0;

  * {
    color: inherit;
    text-align: center;
  }
}

//main title

.main-title {
  @include first-font-medium;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  @include mb-s;

  @include breakpoint(medium down) {
    font-size: 20px;
    line-height: 24px;
    @include mb-xs;
  }
}

//category box

.category-home-container {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-image: var(--data-image);

  @include breakpoint (small down) {
    background-image: var(--data-image-mobile);
    justify-content: flex-end;

    &.left {
      justify-content: flex-start;
    }
  }
}

.category-home-container-text {
  background: $black;
  opacity: 0.75;
  border: 2px solid $white;
  padding: $xxs $xl;
  margin: $xs;

  @include breakpoint (small down) {
    background: none;
    opacity: 1;
    border: none;
    padding: $xxs $xl;
    }
}

.category-home-text {
  font-size: 20px;
  line-height: 24px;
  color: $white;
  text-transform: uppercase;
  @include first-font-semibold;
  text-align: center;
  
  @include breakpoint (small down) {
    font-size: 30px;
    line-height: 32px;
    text-transform: capitalize;
    @include first-font-regular;
    text-align: right;
  }
}

.category-main-container {

  @include breakpoint (medium down ) {
    @include mt-xxs;
  }
}

.category-main-row {
  @include mt-m;
  @include breakpoint (small down) {
    @include mt-xxs;
  }
}

.video-container {
  display: none;
  padding-bottom: 56.25%;
  position: relative;

  #video-home {
    position: absolute;
    top: 0;
    left: 0;
  }
}

//slider
.slider-title {
  font-size: 80px;
  line-height: 80px;
  @include first-font-semibold;

  @include breakpoint (small down) { 
    font-size: 60px;
    line-height: 64px;      
  }
}

.slider-after-title{
  font-size: 20px;
  line-height: 24px;
  @include first-font-semibold;

  @include breakpoint (small down) { 
    font-size: 16px;
    line-height: 24px;      
  }
}

.main-slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.main-slider-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.swiper-container {
  &.swiper-bg-white {
    background-color: $white;
  }
}

//home blocks

.first-title {
  font-size: 30px;
  line-height: 32px;
  @include first-font-medium; 

  &.cms {
    font-size: 35px;
    line-height: 40px;
  }
}

.home-category-image {
  height: 900px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  @include pb-l;
  cursor: pointer;

  @include breakpoint (medium only) {
    height: 450px;
  }

  @include breakpoint (small down) {
    height: 350px;
  }
}

.trending-section {
 @include padding-l; 

 @include breakpoint (medium down) {
  @include padding-xxs; 
  }
}

.subscribe-now-banner {
  height: 500px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  @include pb-s;
  @include pl-s;

  @include breakpoint (medium down) {
    @include pb-xs;
    @include pl-xs;
    @include pr-xs;
  }

  @include breakpoint (small down) {
    height: 600px; 
    }

  &.b2b {
    align-items: center; 
  }

}

.subscribe-now-banner-text {
  background-color: $white;
  @include padding-xs;
}

.paragraph-main {
  p {
    font-size: 20px;
    line-height: 32px;
  }
}

.get-inspired-product-container {
  position: absolute;
  right: 10vw;
  top: 3vh;
  @include breakpoint (medium down) {
    position: relative;
    right: 0;
    top: 0;
  }

}

//banner-social

.banner-social {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-size: cover;
  background-position: center;

  .icon {
    &.facebook {
      width: 40px;
    }
    width: 80px;
  }

  @include breakpoint (medium only) {
    height: 350px;
  }

  @include breakpoint (small down) {
    height: 260px;
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  h2 {
    color: $white;
  }

}

//brands

.bg-best-brands {
  background-color: $first-color;
  @include pt-m;

  h3 {
    color: $white;
    @include mb-0;
  }

  &.man {
    background-color: $second-color;
  }
}

//banner

.adv-home-block {
  position: absolute;
  @include pl-xs;
  @include pb-xxl;
  bottom: 0;
}


//blog


.blog-post-text{
  text-align:left;

  h1{
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  h2{
    font-size: 30px;
    line-height: 40px;

    &.related {
      font-size: 22px;
      line-height: 32px;
    }
  }

  h3{
    font-size: 24px;
    line-height: 32px
  }
}

.post-summary-container-archive {
  .post-date {
    font-size: 14px;
    line-height: 24px;
    border-top: 3px solid $first-color;
    background-color: $white;
    @include pt-xxs;
  }

  .related {
      font-size: 22px;
      line-height: 32px;
      @include breakpoint(small down) {
        max-width: 300px;
      }     
  }
  @include breakpoint(medium only) {
    @include mb-xs;
  }

  @include breakpoint(small down) {
    @include mb-xs;
  }
  .blog-post-image {
    @include breakpoint(small down) {
      display: none;
    }
  }
  .description-blog {
    @include breakpoint(small down) {
      display: none;
    }

    &.truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
  }

  .blog-post-image {
    height: 250px;
    overflow: hidden;
    border-bottom: 3px solid $first-color;
    @include breakpoint(small down) {
      height: 110px;
    }

    div {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      height: inherit;
      transition: all .3s ease-out;
    }
  }

  .bg-post-text {
    background-color: $white;
    border-radius: 8px;
    @include breakpoint(small down) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .blog-post-link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    
    @include breakpoint(small down) {
      @include mt-xs;
    }
  }

  &.home {
    .blog-post-image {
      border-bottom: none;
      height: 300px;
      border-radius: 0 0 4px 4px;
    }
  }

  &.small {
    .blog-post-image {
      height: 130px;
      border-radius: 0 0 4px 4px;
    }
  }
}

.last-post {
 background-color: $white;
 @include box-shadow;

 &:hover {
  @include box-shadow-hover;
 }
}

.main-title-blog {
  font-size: 40px;
  line-height: 48px;
  text-align: left;
  color: $first-color;
  margin: 0;

  @include breakpoint(small down) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
  }
}

.main-blog-post-image {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  @include breakpoint(small down) {
    height: 200px;
    @include mt-xs;
  }

}

.description-post-container {
  padding: 8px 16px;
  background-color: $white;
  @include box-shadow;
  @include breakpoint(small down) {
    border-top: 3px solid $first-color;
  }

}

.blog-post-date {
  text-align: center;
  @include mt-s;
  font-size: 14px;
  line-height: 24px;
}

.title-article-blog{
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  @include mt-xs;
  @include mb-xs;

  @include breakpoint(medium down) {
    font-size: 30px;
    line-height: 40px;
  }

}

.blog-post-wrapper {
  h2 {
    font-size: 30px;
    line-height: 40px;
    @include mt-xs;
    @include mb-xs;
    @include first-font-medium;

    &.related-title {
      font-size: 30px;
      line-height: 40px;
    }

    @include breakpoint(medium down) {
      font-size: 20px;
      line-height: 24px;
    }

    &.featured-product {
      text-align: center;
      color: $black;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
    @include mt-xxs;
    @include mb-xxs;
    @include first-font-medium;
  }

  ul{
      @include mt-xs;
      @include mb-xs; 

    li {
    list-style-type: disc;
    @include ml-s;
    font-size: 16px;
    line-height: 24px;
    }
}

}


.bg-first-color-light {
  background-color: $first-color-light;
}

.bg-second-color-light {
  background-color: $second-color-light;
}


.banner-clickable {
  cursor: pointer;
}


.nowrap {
  td, th {
    white-space: nowrap;
    font-size: 14px;
    line-height: 24px;
  }
}

.table-txt-center {
  th,td{
    text-align: center;
  }
}

ul.list-type-none {
  @include pl-0;
  li {
    list-style-type: none;
    @include ml-0;
  }
}

//blog

.description-date-container {
  .date {
    font-size: 14px;
    line-height: 24px;
    color: $grey-1;
  }
}


//pages

.sizes-tabs {
  .tabs-title {

    a{
      color: $black;
      font-size: 16px;
      line-height: 24px;
    }

    &.is-active {
       a {
         background-color: $white;
         color: $first-color;
         border-bottom: 3px solid $first-color;
          @include first-font-semibold; 
       }
    }



    a:focus, .tabs-title>a[aria-selected=true] {
      background: $white;
        color: $first-color;
        @include first-font-semibold;
    }
  }

  .tabs {
    border: 0;
    border-bottom: 1px solid $grey-2;
  }

  .tabs-content {
    border: 0;
  }

  .tabs-panel {
    padding: 0;
  }
}

.img-height-400 {
  max-height: 400px;
  object-fit: contain;
}