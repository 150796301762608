*,
body {
  @include first-font-regular;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $black;
  text-decoration: none;
  list-style-type: none;

  &:focus {
    outline: none;
  }
}

.body-container {
  min-height: 62vh;
}

.row {
  max-width: 110rem;

  &.centered {
    max-width: 80rem;
  }
}

.flex {
  display: flex;

  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-space-between {
    justify-content: space-between;
  }

  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-baseline {
    align-items: baseline;
  }
  &.flex-column {
    flex-direction: column;
  }
}

.large-centered,
.medium-centered,
.small-centered {
  margin-left: auto;
  margin-right: auto;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.relative {
  position: relative;
}

.margin-section {
  @include mv-xxl;
}

.padding-section {
  @include pv-xxl;
}

.wrapper-no-img {
  @include pt-m;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

.bg-grey {
  background-color: $light-grey;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

//color

.first-color {
  color: $first-color;
}

.cms-wrapper {
  ul {
    @include pl-l;

    li {
      list-style-type: disc;
    }
  }
}

.no-border {
  border: 0;
}

.border-right-line-color {
  border-right: 1px solid $line-color;
}

.business-banner {
  background-color: $light-grey;
  @include pv-xs;
  span {
    @include first-font-semibold;
  }
}

.hover-first-color:hover {
  color: $first-color;
}
