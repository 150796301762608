#main-menu {
  position: relative;
  height: 48px;
  width: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $grey-2;

  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .menu-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: $xxs 0;
    white-space: nowrap;
    position: relative;

    .label {
      @include first-font-medium;
      color: $black;
      font-size: 14px;
      line-height: 24px;
      @include mb-xs;

      &::after {
        display: block;
        content: attr(data-text);
        @include first-font-medium;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }
    }

    .submenu {
      display: none;
      background-color: $white;
      border-top: 2px solid $first-color;
      box-shadow: 0px 19px 20px $box-shadow;
      flex-flow: row wrap;
      position: fixed;
      top: 130px;
      left: 0;
      right: 0;
      min-height: 300px;
      padding-left: calc((100vw - 90rem) / 2);
      padding-right: calc((100vw - 90rem) / 2);

      .title {
        text-transform: uppercase;
        @include text-small;
        @include first-font-semibold;
        color: $dark-grey;
        text-transform: uppercase;
        border-bottom: 1px solid $line-color;
        @include pb-xxs;
        @include mt-xs;
      }

      .items {
        padding: 0 $xxs $xs 0;
        width: 16.6%;

        li {
          a {
            display: inline-block;
            padding: 4px 0;
            @include first-font-medium;
            @include text-small;
            &.see-all {
              @include first-font-semibold;
            }
          }
        }

        &:hover,
        &:active {
          @include first-font-medium;
        }

        &::after {
          display: block;
          content: attr(data-text);
          @include first-font-medium;
          height: 1px;
          color: transparent;
          overflow: hidden;
          visibility: hidden;
        }

        &.extra-submenu .title {
          visibility: hidden;
        }
      }

      .title > * {
        @include first-font-regular;
        font-size: 28px;
        color: $first-color;
      }
    }

    &:hover {
      .label {
        @include first-font-semibold;
      }
      .submenu {
        display: flex;
      }
    }
  }

  .menu-item:not(:last-child) {
    margin-right: 48px;
  }
  .menu-column {
    display: inline-block;
    width: 16.6%;
  }
}

.mobile-menu {
  display: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $overlay-z;

  @include breakpoint(medium down) {
    display: block;
  }

  .overlay {
    background-color: rgba($color: $black, $alpha: 0.7);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease-in;
  }

  .menu-container {
    background-color: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 90%;
    transform: translateX(-100%);
    padding-bottom: $xl * 2;

    .close-mobile-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include ph-xs;
      width: 100%;
      border-bottom: 1px solid $line-color;

      > .button {
        position: absolute;
        top: 6px;
        right: 8px;
      }

      a {
        font-size: 1rem;
        line-height: 1.5rem;
        &.active {
          @include first-font-medium;
          border-bottom: 2px solid $black;
        }
      }

      .professional-button {
        color: $grey-1;
      }
    }
  }

  &.opening {
    visibility: visible;

    .overlay {
      opacity: 1;
    }

    .menu-container {
      animation: slide-in 0.5s forwards;
    }
  }

  &.open {
    visibility: visible;

    .menu-container {
      transform: translateX(0%);
      overflow: auto;
      /*max-height: calc(100vh - 40px);*/
      font-size: 14px;
      line-height: 24px;
    }

    .overlay {
      opacity: 1;
    }

    &.closing {
      .overlay {
        opacity: 0;
      }
      .menu-container {
        animation: slide-out 0.5s forwards;
      }
    }
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

#mobile-menu {
  .menu-item {
    position: relative;
    border-bottom: 1px solid $light-grey;
  }

  &.has-submenu > a,
  .has-submenu > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $xs;

    &.open {
      @include first-font-medium;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $xs;
    }

    /* &::after {
      content: '';
      background-image: url('/skins/current-skin/images/icons/arrow-right-black.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 8px;
      height: 16px;
    }*/
  }
  .menu-column {
    border-top: 1px solid $grey-2;
    @include padding-0;
    p {
      @include mb-0;
      &.title {
        @include pv-xs;
        @include pl-xs;
        font-size: 14px;
        line-height: 24px;

        &.open {
          @include first-font-medium;
        }
      }
    }
  }
  .items {
    padding: 0 $xs;
    border-top: 1px solid $grey-2;

    &.open {
      border-left: 3px solid $first-color;
    }

    li {
      @include pv-xxs;

      &.see-all {
        @include first-font-medium;
      }
    }
  }
  &.open {
    @include first-font-medium;
  }

  .submenu {
    &.open {
      background-color: $light-grey;
    }
  }

  /*   .mobile-menu-services-page {
    .menu-item {
      padding: $xs;
    }
  } */
}
