.profile-container {
  padding: 95px 0 60px;
  @include breakpoint(small down) {
    padding: 0 0 60px;
  }
}

.profile-nav {
    text-transform: uppercase;
    font-size: 14px;

    a {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.1em;
      margin-bottom: 0;

      &:hover, &:active {
        color: $first-color;
      }
  }
    li {
      @include pv-xxs;
      background-color: $white;
    }
    input {
      border: none;
      background: none;
      cursor: pointer;
    }
}
  
.profile-title {
  @include first-font-regular;
  font-size: 30px;
  line-height: 40px;
  @include mb-xxs;
  @include breakpoint(small down) {
    font-size: 24px;
    padding-bottom: 0;
  }
}
ul.profile-nav.help-menu {
  li {
    a.active {
      color: $white;
      @include first-font-medium;
      @include padding-xxs;
      background-color: $black;
    }
  }
}

.profile-separator {
  border: none;
  border-top: 1px solid $line-color;
  margin-top: 40px;
}

.responsive-table {
  overflow-x: auto;
}


.order-row {
  cursor: pointer;
    &:hover,
    &.selected {
      background-color: $light-grey;
    }

    td {
      border-bottom: 1px solid $line-color;
      border-top: 1px solid $line-color;
      padding: 10px 0 8px;
      font-size: 14px;
      letter-spacing: 0.05em;
      text-align:left;
      @include breakpoint(small down) {
        font-size: 14px; 
        }
    }
}

.order-row-indicator-container {
  width:30px;
  position: relative;
}

.order-controls-row {
  margin-bottom: 10px;
}

.order-row-indicator {
  width: 12px;
  position: absolute;
  top: 19px;
  left: 4px;

    &.fa-rotate-90 {
      transform: rotate(180deg);
    }
}

.order-controls {
  display: none;
  border-left: 1px solid $first-color;
  font-size: 14px;
  line-height: 24px;

    > td {
      padding: 20px 20px;
      
    }

    h4 {
      @include first-font-medium;
      @include mb-xs;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      text-align: left;
    }

}

.dispatch-data-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 2px 0;

    > div {
      flex: 1;
      font-size: 14px;
      letter-spacing: 0.05em;
    }
}

.order-controls-return {
  border-collapse: collapse;

    td {
      border-top: 1px solid $line-color;
      padding: 8px;
      line-height: 1.43;
      vertical-align: middle;
    }

    .product-sku {
      font-size: 12px;
      letter-spacing: 0.1em;
      margin-top: 5px;
    }
}

.new-return-request form {
  margin: 40px 0;
}

.new-return-request {
  display: none;
}

.returned-goods-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

    div {
      font-size: 14px;
      letter-spacing: 0.05em;
    }
}

.returned-goods-item {
  border-bottom: 1px solid $line-color;
  padding: 15px 0;
}

.cancel-return-request-form {
  @include breakpoint(small down) {
   @include mt-s;
  }
}
.myorders{
  border-bottom: 1px solid $line-color;
  padding-block-end: 10px;
}

.dl-horizontal {

  dt {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align:left;
      @include first-font-regular;
  }

  dd {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      text-align: right;
  }
}

.orders-table {
  width: 100%;
  border-collapse: collapse;

  p {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 24px;
  }


  .product-sku {
    font-size: 12px;
    margin-top: 5px;
  }
  
  th {
      text-align: left;
      @include first-font-semibold;
      font-size: 12px;
      line-height: 16px;
      min-width: 100px;
  }

  td {
      padding: .5rem .625rem .625rem 0;
      text-align: left;
      border-top: 1px solid $line-color;
      &.return {
        border-bottom: none;
        border-top: none;
      }
  }
}


.button-label{
  font-size: 14px;
  text-transform: uppercase;
}

.table-orders{
  border-collapse: collapse;
  th{
    text-align: left;
    min-width: 100px;
  }

  tbody tr:nth-child(2n) {
    background: none;
  }

  tbody, tfoot, thead {
    border: none;
  }
}

.profile-returns{
  border-bottom: 1px solid $line-color;
  padding-bottom: 10px;
}

.dl-horizontal dt {
  margin-bottom: 5px;
  font-size: 16px;
}

.margin-orders-mobile{
  @include breakpoint(small down) { 
    margin-top:20px;
  }

}

.order-dimension{
  font-size: 16px;
  margin: 2px 0;
  text-transform: initial;
}

.box-wishlist {
  padding: 20px;
  border: 1px solid $line-color;
}

.cart-button_wishlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verified-icon {
  position: absolute;
  width: 20px;
  top: 42px;
  right: 20px;
}

.points-container {
  background-color: $light-grey;
  padding: 16px;
}

.loyality-card-container {
    width: 100%;
    background-color: $black;
    border-radius: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-image-source: linear-gradient(180deg, rgba(242, 140, 0, 0.7) 0%, rgba(230, 0, 126, 0.7) 100%);
    border-image-slice: 1;
    border-width: 5px;
    border: 10px solid;
}

.ean-barcode {
  background-color: $white;
  height: 170px;
  padding: 0 16px;
}

.profile-privacy-row {
  display: flex;
}