
.footer-bottom {
  background-color:$light-grey;
  padding: $s;
  @include breakpoint (medium down) {
    padding: $xxs;
  }


  h3{
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    @include first-font-medium;
    @include mb-0;

    @include breakpoint (medium down) {
      font-size: 14px;
      line-height: 24px;
      @include mt-0;
    }

  }

  ul{
    @include mt-xxs;
    text-align: left;

  }

  li a {

    text-align: left;
    font-size: 14px;
    line-height: 24px;
  }

}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 11px;
    line-height: 16px;
    @include mb-0;
  }

  @include breakpoint (medium down) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include mv-xs;
  }
}

.footer-menu-arrow-container {
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  
  .footer-menu-arrow {
    margin-left: auto;
    width: 16px;

    &.open {
      transform: rotate(180deg);
    }
  }
}

.footer-column-items {
  display: block;
  font-size: 14px;
  line-height: 24px;
  @include pl-0;
  li {
    list-style-type: none;
  }
  @include breakpoint (medium down) {
    display: none;
  }
}
.footer-column {
  &.padding {
    border-bottom: 1px solid $white;
    margin: 0px;
    padding: 0px;
    @include pb-s;
  }

  &.footer-small {
    @include breakpoint (medium down) {
      cursor: pointer;
      border-bottom: 1px solid $grey-2;
      margin: 0px;
      padding: $xs;
    }
  }
}

.footer-column-title {
  margin-right: auto;
  @include breakpoint (medium down) {
    margin-bottom: 0;
  }
}


//newsletter
#subscribe-newsletter {
  position: relative;
  p.newsletter {
      font-size: 12px;
      line-height: 16px;
      @include ml-s;
  }
}

#subscribe-newsletter input[type=email] {
  background: $white;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid $black;

  &.error {
    background-color: $danger;
    @include mb-xxs;
  }
}

#subscribe-newsletter input[type=email]::placeholder {
  opacity: 1;
  color: $dark-grey;
}

#newsletter-step2 {
  position: relative;

  @include breakpoint(medium down) {
      margin: 0 32px;
  }

  .button.button-form {
      width: 100%;
  }
}

.newsletter-container {
  background-color: $line-color;
  @include padding-m;

  h4 {
    @include margin-0;
  }
}

.sub-footer {
  border-top: 1px solid $grey-2;
  @include padding-xs;
  background-color: $light-grey;

  .border-right {
    border-right: 1px solid $grey-2;
  }
}

.footer-before {
  background-color: $light-grey; 
  @include mt-xxl;
}

.footer-icons-container {
  display: flex;
  align-items: center;

  p {
    @include mb-0;
    font-size: 14px;
  }
}

.footer-feature {
  p {
    @include mb-0;
    font-size: (7/8) * 1rem;
    line-height: 1.25rem;
  }
}

.powered-by {
  margin-top: 6px;
}