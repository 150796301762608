// button
.btn {
    @include first-font-semibold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.12em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 $xxs;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 50px;

    &.btn-primary {
        background-color: $black;
        border: 1px solid $black;
        color: $white;

        &:not(:disabled) {
            &:hover {
                background-color: $black-hover;
                border-color: $black-hover;
            }
        }
    }

    &.btn-secondary {
        color: $black;
        border: 1px solid $black;
        background-color: transparent;

        &:not(:disabled) {
            &:hover {
                color: $first-color;
            }
        }
    }

    &.btn-third {
        color: $white;
        border: 1px solid $first-color;
        background-color: $first-color;
    }

    &.btn-underline {
        color: $first-color;
        background-color: transparent;
        text-decoration: underline;
        @include padding-0;

        &.black {
            color: $black;
        }

        &.white {
            color: $white;
        }

        &:not(:disabled) {
            &:hover {
                color: $first-color-hover;
            }
        }
    }

    &.btn-payments {
        color: $black;
        border: none;
        padding: 10px 0px;
        background-color: $grey-paypal;

        &:hover {
            box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);
        }
    }

    &.btn-confirm-order {
        @include breakpoint(medium down) {
            position: fixed;
            bottom: 0px;
            z-index: 1000;
            box-shadow: 0 0 8px $box-shadow;
            left: 0;
            border-radius: 0px;
            height: 64px;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    &.btn-confirm-order-guest {
        @include breakpoint(medium down) {
            position: fixed;
            bottom: 64px;
            height: 64px;
            border-left: none;
            border-right: none;
            border-radius: 0px;
            z-index: 1000;
            box-shadow: 0 0 8px $box-shadow;
            left: 0;
            background-color: $white;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    &.btn-padding {
        @include ph-l;
    }

    &.btn-orders {
        padding: $xxs $xs;
    }

    &:disabled {
        opacity: 0.7;
    }

    &.buy-now {
        @include breakpoint(small down) {
            font-size: 12px;
            line-height: 16px;
            height: 32px;
        }
    }
}

select {
    margin: 0;
    border: none;
    border-radius: 4px;
    height: 40px;
    border: 1px solid $black;
    box-shadow: none;

    &:hover,
    &:focus {
        border: 1px solid $first-color;
    }
}

// checkbox
.styled-checkbox {
    width: 0;
    visibility: hidden;
    position: absolute;

    & + label,
    & + label a {
        position: relative;
        padding: 0;
        /*display: flex;*/
        align-items: center;

        &.is-invalid-label,
        &.is-invalid-label a {
            color: $danger;
        }
    }

    & + label {
        &:before {
            content: "";
            @include mr-xxs;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            background: none;
            position: relative;
            top: 0px;
            left: -2px;
            border: 1px solid $black;
        }

        &.black {
            &:before {
                border: 1px solid $black;
            }
        }
    }

    &.styled-radio {
        & + label:before {
            border-radius: 50%;
        }
    }

    &:hover + label:before {
        background: $first-color;
    }

    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before {
        background: $first-color;

        &.fix-newsletter {
            border: 1px solid $black;
        }
    }

    &:disabled + label {
        color: $light-grey;
        cursor: auto;
    }

    &:disabled + label:before {
        box-shadow: none;
        background: $light-grey;
    }

    &:checked + label:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 11px;
        background: $white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $white, 4px 0 0 $white, 4px -2px 0 $white, 4px -4px 0 $white, 4px -6px 0 $white,
            4px -8px 0 $white;
        transform: rotate(45deg);
    }

    &.styled-radio {
        &:checked + label:after {
            top: 13px;
        }
    }
}

input.error {
    color: #cc4b37;
    border-color: #cc4b37;
    background-color: #faedeb;

    &::placeholder {
        color: lighten(#cc4b37, 10%);
    }

    & + label {
        color: #cc4b37;
    }
}

label.error {
    color: #cc4b37;
    position: relative;
    top: -1rem;
}
